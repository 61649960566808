/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 62.0.20-v202406060201
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * The list of configurations for finding items
 */
export interface ItemFinderConfigurationV1 {
    /**
     * The type of finder
     */
    "finderType": FinderTypeEnum;
    /**
     * If true the items found by this configuration will be included in the output, if false then the results will be excluded from the output (even if found by another finder configuration)
     */
    "isInclude": boolean;
    /**
     * Used if finder type is SwapAcrossAssets, this specifies the ID of the root asset whose immediate children will be iterated.
     */
    "rootAssetId"?: string;
    /**
     * Used if finder type is SwapAcrossAssets, this specifies the ID of the formula item that will be swapped with each asset beneath the root. Each successful swap will be added to the list of found items
     */
    "swapItemId"?: string;
}

export enum FinderTypeEnum {
    SWAPACROSSASSETS = 'SWAP_ACROSS_ASSETS' as any
}
